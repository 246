import React, { useEffect, useState } from "react";
import SwitchNetworkModal from "../pages/home/components/switchNetworkModal";
import { useDetectAndSwitchNetwork } from "../hooks/useNetwork";
import { useAccount } from 'wagmi';

const App = React.createContext({});

export function useNetworkContext() {
    return React.useContext(App);
}


export default function Provider({ children }) {
    const [modalOpen, setModalOpen] = useState(false);
    const { isConnected } = useAccount();
    const switchBnbNetwork = useDetectAndSwitchNetwork(() => {
        setModalOpen(true);
    }, setModalOpen)

    return (
        <App.Provider
            value={null}
        >
            {
                isConnected &&
                <SwitchNetworkModal open={modalOpen} setOpen={setModalOpen} switchBnbNetwork={switchBnbNetwork} />
            }
            {
                children
            }

        </App.Provider>
    )
}