import React, { useEffect, useState } from "react";
import {
    Box,
    Button,

    Typography,
} from "@mui/material";

import imgWallet from "../../assets/images/wallet.png";
import imgLogo from "../../assets/images/logo.png";
import { BiMenu } from "react-icons/bi";

import styles from "./style.module.css";
import { LeftBar } from "..";
import { NavLink } from "react-router-dom";
import MobileDrawer from "../Drawer";
import WalletConnectButton from "../WalletConnectButton.js";
import { useWalletAccount, useWalletDisconnect, useWalletModal, useWalletSwitchNetwork } from "../../hooks/useWallet"
// import MobileDrawer from "../Drawer";

const Header = ({ mobile, rightimg, leftimg, userid2, userid, mainHeading }) => {
    const [user, setUser] = useState(false);
    const { open } = useWalletModal();
    const { isConnected, address } = useWalletAccount();
    const { disconnect } = useWalletDisconnect();

    // const navigate = useNavigate();

    const connectWallet = async () => {
        open();
    };

    return (
        <Box className={styles.mainContainer} mt={2}>
            {mobile <= 768 ? (
                <Box
                    sx={{
                        display: { sm: "flex", lg: "none" },
                        //  color: IconColor || "black",
                    }}
                >
                    <MobileDrawer />
                </Box>
            ) : null}
            <Box />

            {mobile <= 768 ? <img src={imgLogo} alt="Flash Technology" /> : null}

            {mobile <= 768 ? (
                <Button sx={{ background: "#FBBF04", padding: '12px 6px 12px 12px', minWidth: '0px', borderRadius: '12px', display: 'flex', justifyContent: 'center' }} onClick={connectWallet}>
                    <img src={imgWallet} className="me-1" style={{ width: '25px', margin: 'auto' }} alt="Wallet" />
                </Button>
            ) : null}

            {mobile > 768 ? (
                <Box>
                    <Typography className={styles.m_headerName}>{mainHeading}</Typography>
                    {/* <h4 className="text-white">Staking</h4> */}
                </Box>
            ) : null}

            {mobile > 768 ? (
                <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    display={{ md: "none", lg: "flex" }}
                >
                    <WalletConnectButton userid={userid} rightimg={rightimg} />
                </Box>
            ) : null}
        </Box>
    );
};

export default Header;
