import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import imgWallet from "../../assets/images/wallet.png";
import styles from "./style.module.css";

import dropdown from "../../assets/images/dropdown.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWalletAccount, useWalletDisconnect, useWalletModal, useWalletSwitchNetwork } from "../../hooks/useWallet";

const WalletConnectButton = ({ userid, rightimg }) => {
  const [user, setUser] = useState(false);
  const { open } = useWalletModal();
  const { isConnected, address } = useWalletAccount();
  const { disconnect } = useWalletDisconnect();

  // const navigate = useNavigate();

  const connectWallet = async () => {
    open();
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setUser(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  return (

    <>
      {
        !isConnected ?
          <Button
            sx={{
              backgroundColor: "#FBBF04",
              color: "black",
              width: "180px",
              fontSize: "16px",
              borderRadius: "10px",
              textTransform: "capitalize",
              height: "50px",
              fontWeight: 500,
              ":hover": {
                backgroundColor: "#FBBF04",
              },
            }}
            onClick={() => connectWallet()}
            startIcon={<img src={imgWallet} className="me-1" alt="Wallet" />}
          >
            Connect Wallet
          </Button >
          :
          <Button

            sx={{
              display: "flex",
              padding: '10px',
              flexDirection: 'column',
              height: 46,
              border: "1px solid rgba(255,255,255,0.2)",
              borderRadius: "40px",
              alignItems: 'center',
              marginLeft: '15px',
              position: 'relative',
              cursor: 'pointer'
            }}>
            <Box onClick={() => setUser(!user)} className={styles.usercontainer1}>
              <Box>
                <Typography className={styles.id1}>{address.slice(0, 7) + "....." + address.slice(37)}</Typography>
              </Box>
              {/* <img className={styles.userimg} src={rightimg} /> */}

              <img className={styles.drop1} src={dropdown} />
            </Box>
            {
              user &&
              <Button ref={wrapperRef} className={styles.userDropdown1} onClick={() => disconnect()}>
                Disconnect
              </Button>
            }
          </Button >
      }
    </>
  );
};

export default WalletConnectButton;
