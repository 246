import { useAccount } from "wagmi";
import { useFTTTokenApprove, useFTTTokenApproveWrite, useTokenContractApproveRead } from "./useContract";


export function useAllowance() {
    const { address } = useAccount();
    return useTokenContractApproveRead(address)
}

export function useApprove(args) {
    return useFTTTokenApproveWrite("approve", args);
}
