import React from "react";
import { mainnet, bsc, bscTestnet, fantomTestnet, polygon } from "wagmi/chains";
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'


const projectId = process.env.REACT_APP_CONNECT_PROJECT_ID || "";

const metadata = {
    name: 'Flash-Staking',
    description: 'Flash-Staking',
    url: 'https://flash-staking.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886']

}

const chains = [mainnet, bsc, bscTestnet, fantomTestnet, polygon]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({ wagmiConfig, projectId, chains })


export default function FlashProvider({ children }) {


    return (
        <WagmiConfig config={wagmiConfig}>
            {
                children
            }
        </WagmiConfig>
    )
}