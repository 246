import { useAccount } from "wagmi";
import { useStakingContractReads, useStakingContractWrite } from "./useContract";

export function useStakingTopInformation() {
    const { address } = useAccount();
    return useStakingContractReads([{
        functionName: "getUser",
        args: [address]
    }])
}



export function useStake(args) {
    return useStakingContractWrite("stake", args);
}
export function useUnstake(args) {
    return useStakingContractWrite("unStake", args);
}