import { useAccount } from "wagmi";
import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { NFT_CONTRACT_ADDRESS, getConstant, NFT_AIRDROP_CONTRACT_ADDRESS } from "../utils/constant";
import nftABI from '../abi/nft.json'
import nftAirdropABI from '../abi/nftairdrop.json'

export function useNFTAmount() {
    const { address } = useAccount();
    return useContractRead({
        address: address,
        // address: "0x0054ef36214005b3f3878536b07900002eb7f46c",
        abi: nftABI,
        functionName: 'balanceOf',
        args: [address],
        chainId: 137,
        watch: true
    });
}


export function useContractWriteBasic(address, abi, functionName, args) {
    const { config } = usePrepareContractWrite({
        address,
        abi,
        functionName,
        args
    })
    return useContractWrite(config);
}


export function useNFTClaim(amount) {
    const { address } = useAccount();
    const { config, refetch, isSuccess } = usePrepareContractWrite({
        address: getConstant(NFT_AIRDROP_CONTRACT_ADDRESS),
        abi: nftAirdropABI,
        functionName: "claim",
        args: [amount],
        chainId: 56,
    })

    return { contractWrite: useContractWrite(config), refetch, isSuccess };
}



export function useAirDropAmount() {
    const { address } = useAccount();
    return useContractRead({
        address: getConstant(NFT_AIRDROP_CONTRACT_ADDRESS),
        abi: nftAirdropABI,
        functionName: 'getPool',
    });
}


export function useAirDropAvailable() {
    const { address } = useAccount();
    return useContractRead({
        address: getConstant(NFT_AIRDROP_CONTRACT_ADDRESS),
        // address: "0x0054ef36214005b3f3878536b07900002eb7f46c",
        abi: nftAirdropABI,
        functionName: 'getUser',
        chainId: 56,
        args: [address],
        watch: true
    });
}

