import { useContractRead, useContractWrite, useContractReads, usePrepareContractWrite } from 'wagmi'
import { useBaseWalletClient } from './useWallet'
import stakingABI from "../abi/staking.json";
import tokenABI from "../abi/token.json"
import nftABI from "../abi/nft.json"
import { BUSDADRESS, STAKING_CONTRACT_ADDRESS, getConstant, FTTAddress, NFT_CONTRACT_ADDRESS } from '../utils/constant';

export function useContractReadsBasic(contractFuncList) {
    return useContractReads({
        contracts: contractFuncList
    })
}

export function useContractWriteBasic(address, abi, functionName, args) {
    const { config } = usePrepareContractWrite({
        address,
        abi,
        functionName,
        args
    })
    return useContractWrite(config);
}

export function useStakingContractReads(contractFuncList) {
    const staking_address = getConstant(STAKING_CONTRACT_ADDRESS);
    const abi = stakingABI;
    const funcList = contractFuncList.map((item) => {
        return {
            address: staking_address,
            abi,
            ...item,
        }
    })
    return useContractReadsBasic(funcList);
}
export function useStakingContractWrite(functionName, args) {
    const staking_address = getConstant(STAKING_CONTRACT_ADDRESS);
    const abi = stakingABI;
    return useContractWriteBasic(staking_address, abi, functionName, args)
}

export function useBUSDContractReads(contractFuncList) {
    const busd_address = getConstant(BUSDADRESS);
    const busdABI = tokenABI;
    const funcList = contractFuncList.map((item) => {
        return {
            address: busd_address,
            abi: busdABI,
            ...item
        }
    })
    return useContractReadsBasic(funcList);
}



export function useFTTTokenApprove(contractFuncList) {
    const ftt_address = getConstant(FTTAddress);
    const abi = tokenABI;
    const funcList = contractFuncList.map((item) => {
        return {
            address: ftt_address,
            abi,
            ...item,
        }
    })
    return useContractWriteBasic(funcList);
}

export function useFTTTokenApproveWrite(functionName, args) {
    const ftt_address = getConstant(FTTAddress);
    const abi = tokenABI;
    return useContractWriteBasic(ftt_address, abi, functionName, args)
}


export function useTokenContractApproveRead(address) {
    const ftt_address = getConstant(FTTAddress);

    const abi = tokenABI;
    return useContractRead({
        address: ftt_address,
        abi: abi,
        functionName: 'allowance',
        watch: true,
        args: [address, getConstant(STAKING_CONTRACT_ADDRESS)],
    })
}