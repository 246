import React, { useEffect, useState } from "react";
import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";

import Chart from "react-apexcharts";

import styles from "./style.module.css";
import { getChartData } from "../../../services/getContract";
import { formatEther, parseEther } from "../../../utils";
import { formatUnits } from "viem";
const Staking = (Props) => {
	const { totalRewardDistributed, setFilter, filter } = Props;
	const [chartData, setChartData] = useState([])
	const [category, setCategory] = useState([])
	const [showData, setShowData] = useState([])
	const [showDate, setShowDate] = useState([])
	const [filterDay, setFilterDay] = useState(-1)
	const [step, setStep] = useState(1)
	const options = {
		chart: {
			toolbar: { show: false },
		},
		stroke: {
			curve: "smooth",
			colors: ["#FBBF04", "#FBBF04", "#FBBF04"],
		},
		markers: {
			size: 0,
			sizeOffset: 12,
			colors: '#FBBF04',
			strokeColors: '#FBBF04',
			strokeWidth: 12,
			strokeOpacity: 0.2,
			fillOpacity: 1,
			discrete: [],
			shape: "circle",
			radius: 2,
			offsetX: 0,
			offsetY: 0,
			showNullDataPoints: true,
			hover: {
				size: 7,
			}
		},

		tooltip: {
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				return '<div class="chart-tooltip-container">' +
					'<span class="chart-tooltip-font-date">' + w.globals.categoryLabels[dataPointIndex] + '</span><br/>' +
					'<span class="chart-tooltip-font" >' + series[seriesIndex][dataPointIndex] + ' FTT </span>' +
					'</div>'
			}
		},

		xaxis: {
			categories: showDate,
			labels: {
				style: {
					colors: "white",
					fontSize: '12px',
				}
			},
			tooltip: false
		},
		yaxis:
		{
			categories: [1, 2, 3, 4, 5, 6, 7, 8],
			labels: {
				style: {
					colors: "white"
				}
			},
		},


	};
	const series = [
		{
			data: showData,
		},
	];

	useEffect(() => {
		const getInitData = async () => {
			let res = await getChartData();
			console.log("🚀 ~ file: Staking.js:91 ~ getInitData ~ res:", res)
			let tmpData = [];
			let tmpCategory = [];
			res?.data?.map((item) => {
				tmpData.push(formatUnits(item.value, 18))
				console.log("🚀 ~ file: Staking.js:96 ~ res?.data?.map ~ tmpData:", tmpData)
				var date = new Date(Date.parse(item.date))
				const month = date.toLocaleString('default', { month: 'short', day: 'numeric' });
				tmpCategory.push(month)
			})

			let tmpStep = Math.floor(tmpCategory.length / 10) + 1
			setStep(tmpStep)
			setChartData(tmpData)
			setCategory(tmpCategory)
			let tmpVar = [];
			tmpCategory.map((item, index) => {
				if (index % tmpStep === 0)
					tmpVar.push(item)
				else {
					tmpVar.push("")
				}
			}
			)
			setShowData(tmpData);
			setShowDate(tmpVar)
		}
		getInitData();
	}, [])

	useEffect(() => {
		console.log("🚀 ~ file: Staking.js:125 ~ useEffect ~ filterDay:", filterDay)

		if (filterDay == -1) {
			console.log("🚀 ~ file: Staking.js:130 ~ category.map ~ step:", step)
			setShowData(chartData)
			let tmpVar = [];
			category.map((item, index) => {
				if (index % step === 0)
					tmpVar.push(item)
				else {
					tmpVar.push("")
				}
			}
			)
			setShowDate(tmpVar)
		}
		else {
			setShowData(chartData.slice(-1 * filterDay))
			setShowDate(category.slice(-1 * filterDay))
		}
	}, [filterDay])

	return (
		<Grid item lg={7} xs={12}>
			<div className={styles.ps_container}>
				<Box
					display={"flex"}
					justifyContent="space-between"
					alignItems="center"
					flexDirection={{ xs: "row", lg: "row" }}
				>
					<Box sx={{ flex: 1 }}>
						<h4 className="text-white" style={{ fontSize: "20px", margin: '0px' }}>
							Staking
						</h4>
					</Box>
					<Box sx={{ flex: 1, width: "100%", display: "flex" }}>
						<Box sx={{ flex: 1 }}>
							<FormControl fullWidth sx={{}}>
								{/* <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "white" }}
                >
                  Staked
                </InputLabel> */}
								<Select
									value={filter}
									disableUnderline
									variant="standard"
									sx={{
										padding: { xs: '3px 5px', sm: '5px 15px' },
										fontSize: { xs: '14px', sm: '18px' },
										fontWight: 500,
										borderRadius: "5px",
										color: "white",
										background:
											"linear-gradient(180deg, rgba(251, 191, 4, 0) 0%, rgba(251, 191, 4, 0.05) 100%)",
										border: "1px solid rgba(251, 191, 4, 0.2)",
										// border: " none  !important",
										// outline: "none !important",
										// borderColor: "white",
										".MuiSelect-icon": {
											color: "white",
										},
									}}
								>
									<MenuItem onClick={() => setFilter(-1)} value={-1}>Select</MenuItem>
									<MenuItem onClick={() => setFilter(10)} value={10}>Ten</MenuItem>
									<MenuItem onClick={() => setFilter(20)} value={20}>Twenty</MenuItem>
									<MenuItem onClick={() => setFilter(30)} value={30}>Month</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Box sx={{ flex: 1, paddingLeft: "10px" }}>
							<FormControl fullWidth sx={{}}>
								{/* <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "white" }}
                >
                  Last Week
                </InputLabel> */}
								<Select
									value={filterDay}
									disableUnderline
									variant="standard"
									sx={{
										padding: { xs: '3px 5px', sm: '5px 15px' },
										fontSize: { xs: '14px', sm: '18px' },
										fontWight: 500,
										borderRadius: "5px",
										color: "white",
										background:
											"linear-gradient(180deg, rgba(251, 191, 4, 0) 0%, rgba(251, 191, 4, 0.05) 100%)",
										border: "1px solid rgba(251, 191, 4, 0.2)",

										".MuiSelect-icon": {
											color: "white",
										},
									}}
								>
									<MenuItem onClick={() => setFilterDay(-1)} value={-1}>All Time</MenuItem>
									<MenuItem onClick={() => setFilterDay(7)} value={7}>Last Week</MenuItem>
									<MenuItem onClick={() => setFilterDay(30)} value={30}>Thirty</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Box>
				</Box>
				<Box sx={{ flex: 1 }} display="flex" alignContent={"center"} style={{ color: 'white', paddingTop: '15px' }} >
					<h4 className="text-white" style={{ fontSize: "20px" }}>
						{totalRewardDistributed ? Math.floor(totalRewardDistributed * 100) / 100 : 0} &nbsp;
					</h4>
					<span className="" style={{ opacity: '0.5' }}>/ FTT</span>
				</Box>

				<Box sx={{ marginTop: "0px" }}>
					<Chart
						options={options}
						series={series}
						type="line"
						width="100%"
						height="280px"
					/>
				</Box>
			</div>
			<br className="mo-show-only" />
		</Grid>
	);
};

export default Staking;
