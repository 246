import { providers } from "ethers";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";

if (process.env.REACT_APP_DEV_MODE == "TRUE")
    axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
else
    axios.defaults.baseURL = process.env.REACT_APP_URL;

const getChartData = async (props) => {
    try {
        var result = await axios.get("/api/staking-history/", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}

const getNFTChartData = async (props) => {
    try {
        var result = await axios.get("/api/nft-history/", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}


const getTokenData = async (props) => {
    try {
        var result = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=flash-3-0&vs_currencies=usd", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}

const getFTTData = async (props) => {
    try {
        var result = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=flash-technologies&vs_currencies=usd", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}

const nftClaim = async (props) => {
    try {
        var result = await axios.post("/api/nft-claim/", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}

const getNFTClaimData = async (props) => {
    try {
        var result = await axios.get("/api/nft-history/", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}


const getNFTAmount = async (props) => {
    try {
        var result = await axios.post("/api/nft-amount/", props, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return result.data;
    } catch (error) {
        return error.response
    }
}



export {
    getChartData, getTokenData, getFTTData, nftClaim, getNFTClaimData, getNFTAmount, getNFTChartData
}
